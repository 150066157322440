import Vue from 'vue'
import Vuex from 'vuex'
import fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    userProfile: {},
    uploadedAudioFiles: [],
    hasGotFirstUserUpdate: false
  },
  mutations: {
    setUserProfile (state, val) {
      state.userProfile = val
    },
    setLoggedIn (state, val) {
      state.loggedIn = val;
    },
    setUsersAudioFiles (state, val) {
      state.uploadedAudioFiles = _.cloneDeep(val);
    },
    setHasGotFirstUserUpdate(state, val) {
      state.hasGotFirstUserUpdate = val;
    }
  },
  
  actions: {
    async login ({ dispatch }, user) {
      // // sign user in
      // const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

      console.log("Store has user logged in")
      console.log(user);
      this.commit('setLoggedIn', true);
      window.localStorage.setItem('aritune-loggedInUserId', user.uid)

      // is this in fb storage?
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      if(userProfile.exists === false) {
        fb.usersCollection.doc(user.uid).set(user)
        .then(() => {
          console.log("Created userprofile entry");
          dispatch('subscribeToProfileUpdates', user.uid)
          dispatch('subscribeToAudioFileUpdates', user.uid)
        })
        .catch((error) => {
          console.log("Error writing userprofile");
          console.log(error);
        })
      }
      else {
        console.log("User profile fetched from fb storage")
        console.log(userProfile);  
        dispatch('subscribeToProfileUpdates', user.uid)
        dispatch('subscribeToAudioFileUpdates', user.uid)
      }
    },
    async subscribeToProfileUpdates({dispatch}, userId) {
      fb.usersCollection.doc(userId).onSnapshot((doc) => {
        console.log("[Vuex] Snapshot change to user profile")
        console.log(doc.data());
        this.commit('setUserProfile', doc.data());
        this.commit('setHasGotFirstUserUpdate', true);
      });
    },
    async subscribeToAudioFileUpdates({}, userId) {
      fb.audioFilesCollection.where("userId", "==", userId).onSnapshot((docs) => {
        // console.log("[Vuex] Snapshot change to audio file uploads");
        let audioFileDocs = [];
        docs.forEach(doc => {
          // console.log(doc.data())
          const docWithId = doc.data();
          docWithId.id = doc.id;
          audioFileDocs.push(docWithId);
        })
        // console.log(audioFileDocs);
        this.commit('setUsersAudioFiles', audioFileDocs);
        
      });
    },
    async logout ({dispatch}) {
      await fb.auth.signOut();
      this.commit('setLoggedIn', false);
      this.commit('setUserProfile', {})
    }
  }
})
