<template>
  <div id="login">
      <section>
        <div v-show="isLoggedIn">
          <button class="button" @click="logOut">Log Out</button>
        </div>
        <div v-show="testingMagicLinkLogin">
          <h3>Authenticating your magic link...</h3>
        </div>
        <div v-show="!isLoggedIn && !testingMagicLinkLogin">
          <h1>Welcome to AirTune</h1>
          <h3 style="margin: 10px">Sign In to Upload Music to Use With the App</h3>
          <div style="margin: auto; margin-top: 20px; min-width: 250px; width: 50%">
            <!-- <v-form> -->
              <v-text-field label="Email" v-model="loginForm.email" :rules="emailRules"
              @keydown.enter="emailEntered"/>
              <v-btn @click="emailEntered">Sign In</v-btn>
            <!-- </v-form> -->
          </div>
          <div style="white-space:pre-wrap; margin-top: 25px">
            {{status}}
          </div>
          <div id="videoSection" style="margin: auto;
           margin-top: 20px; min-width: 350px; max-width: 50%">
            Download the iPhone app 
            <a href="https://apps.apple.com/us/app/airtune/id1570196279">here</a>
            <br><br>
            <video controls width="100%" preload="metadata">
            <source src="alon-demo-airtune.mov">
            </video>
          </div>
          <!-- <section id="firebaseui-auth-container"></section> -->
        </div>
      </section>
  </div>
</template>

<script>
import firebase from "firebase";
var firebaseui = require("firebaseui");
import "firebaseui/dist/firebaseui.css";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      testingMagicLinkLogin: false,
      status: ''
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('logout');
    },
    emailEntered(evt) {
      const email = this.loginForm.email;
      const isvalid = /.+@.+\..+/.test(email)
      if(!isvalid || email.length === 0) {
        console.log('not valid email')
        return;
      }
      this.sendMagicLink(email);
    },
    sendMagicLink(email) {
      let url = this.isDev() ? 'http://localhost:8080/#/?signin=email'
      : 'https://airtune.io/#/?signin=email';
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: url,
        // This must be true.
        handleCodeInApp: true
      };
      this.status = "Preparing email link..."
      firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          console.log("We sent you the link")
          this.status = "We have emailed you a magic link.\n\nCheck your email " +
          "(including spam!) and click the link to login."
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email);
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log("Error sending link")
          console.log(errorCode);
          console.log(errorMessage);
        });
    },
    checkForEmailMagicLinkInUrl() {
      this.testingMagicLinkLogin = true;
      // Confirm the link is a sign-in with email link.
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        var email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
        }
        // The client SDK will parse the code from the link for you.
        firebase.auth().signInWithEmailLink(email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            // window.localStorage.removeItem('emailForSignIn');
            const userObj = result.user;
            console.log(userObj);
            window.history.replaceState(null, null, window.location.pathname);
            this.testingMagicLinkLogin = false;
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            this.testingMagicLinkLogin = false;
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
      else {
        this.testingMagicLinkLogin = false;
      }
    },
    isDev() {
      return process.env.NODE_ENV === 'development';
    }

  },
  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn;
    }
  },
  mounted() {
    if(this.isDev()) {
      this.loginForm.email = 'mhavryliv@gmail.com'
    }
    this.checkForEmailMagicLinkInUrl();
    return;
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    var uiConfig = {
        // signInSuccessUrl: "/",
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID
          // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          // firebase.auth.GoogleAuthProvider.PROVIDER_ID
          ],
          callbacks: {
            signInSuccessWithAuthResult: () => {return false}
          }
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  }
}
</script>