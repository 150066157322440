<template>
  <div id="dashboard">
    <Login v-show="!isLoggedIn" />
    <div v-show="isLoggedIn">
      <v-container style="text-align: center">

        <h2>Your AirTune Dashboard</h2>
        <h4>Upload music that you want to access in the AirTune app</h4>

        <br>
        <div v-if="username === null && hasGotFirstUserUpdate" 
        style="display: flex; justify-content: center; flex-direction: column;
        align-items: center">
          <v-text-field style="width: 250px"
          label="Please choose a username" v-model="requestedUsername"
          counter maxlength="25" @keydown.enter="chooseUsername"
          :rules="[usernameAvailable]"
          />
          <v-btn @click="chooseUsername" style="margin-top: 10px"
          >Submit</v-btn>
        </div>
        <div v-if="username !== null">
          Hi, {{username}}<br>(Not you? <a href="" @mousedown="logoutPressed">Logout</a>)
        </div>
   
        <div v-if="username !== null" style="margin-top: 25px">
          <div>
            <v-btn @click="selectFilesClicked">Select MP3 Files To Upload</v-btn>
            <div>(Must be 44.1kHz whilst AirTune is in beta)</div>
            <input type="file" id="file-selector" accept="audio/mpeg" multiple
                class="d-none" @change="onFileChange"
                ref="uploader"/>
          </div>
          <div id="pendingUploadArea" v-show="showPendingUpload" style="margin-top: 20px">
            <v-card>
              <v-card-title>
                <div style="text-align: left">
                  <h4>Files Pending Upload</h4>
                  <div style="text-align: left; margin-top: 20px">
                    <v-btn @click="uploadPendingFiles">
                      Upload Pending Files
                    </v-btn>
                    <v-btn @click="clearPendingFiles" style="margin-left: 16px">
                      {{onlyPendingHaveErrors ? "Done" : "Cancel"}}
                    </v-btn>                
                  </div>
              </div>

              </v-card-title>
              <v-simple-table class="pendingUploadTable">
                <thead>
                  <tr>
                    <th>Filename</th>
                    <th>Duration</th>
                    <th>Sample Rate</th>
                    <th>File Size (MB)</th> 
                    <th>Errors</th>
                    <th class="uploadStatusCell">Upload Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in fileDataPendingUpload" :key="file.fileRef.name">
                    <td>{{file.fileRef.name}}</td>
                    <td>{{file.formatInf.duration ? sec2Min(file.formatInf.duration) : ''}}</td>
                    <td>{{file.formatInf.sampleRate}}</td>
                    <td>{{(file.fileRef.size/1000000).toFixed(2)}}</td>
                    <td class="pendingUploadError">{{file.errs.join("\n")}}</td>
                    <td class="uploadStatusCell">{{file.uploadStatus}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>

          </div>

          <div class="existingAudioFilesDiv">
            <v-card>
              <v-card-title>
                <div style="text-align: left">
                <h4>Uploaded Audio Files</h4>
                  <div style="font-size: 0.85em"> {{uploadedFileSummary}} </div>
                  <div style="margin-top: 5px; min-height: 36px">
                    <v-btn v-show="selectedAudioFiles.length > 0"
                    @click="deleteSelectedFiles">
                      Delete {{selectedAudioFiles.length}}
                    </v-btn>
                  </div>
                </div>
                  <v-spacer/>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details />
              </v-card-title>
              <v-data-table
                v-model="selectedAudioFiles"
                item-key="name"
                show-select
                :headers="audioFileHeaders"
                :items="audioFileItems"
                :items-per-page="15"
                :search="search"
                class="elevation-1"
              >
                <template v-slot:item.name="{item}">
                  <a :href=item.url target="_blank">{{item.name}}</a>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
        </v-container>
      </div>
  </div>
</template>

<script>
var musicMetadata = require ('music-metadata-browser')
import fb from '@/firebase.js'
import Login from './Login.vue';

export default {
  components: { Login },
  name: 'Dashboard',
  data() {
    return {
      fileDataPendingUpload: [],
      onlyPendingHaveErrors: false,
      showPendingUpload: false,
      selectedAudioFiles: [],
      search: '',
      requestedUsername: '',
      requestedUsernameLastSubmitted: '',
      usernameAvailableInDatabase: true
    }
  },
  watch: {
    '$store.state.uploadedAudioFiles': function() {
      // this.checkDataOnAllFiles(this.fileDataPendingUpload);
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn;
    },
    hasGotFirstUserUpdate() {
      return this.$store.state.hasGotFirstUserUpdate;
    },
    username() {
      if(this.$store.state.userProfile.displayName) {
        return this.$store.state.userProfile.displayName;
      }
      return null;
    },
    sizeTotalOfUploadedFiles() {
      let items = this.$store.state.uploadedAudioFiles;
      let sizeSum = 0;
      items.forEach(item => {
        sizeSum += item.size
      })
      return sizeSum;
    },
    uploadedFileSummary() {
      let items = this.$store.state.uploadedAudioFiles;
      let str = items.length + " files, total size: ";
      let sizeSum = this.sizeTotalOfUploadedFiles / 1000000;
      str += sizeSum.toFixed(2) + "MB";
      str += " (500MB max)";
      return str;
    },
    audioFileHeaders() {
      return [
        {text: "Filename", align: 'start', sortable: true, value: 'name'},
        {text: "Duration", align: 'start', sortable: true, value: 'duration'},
        {text: "Sample Rate", align: 'start', sortable: true, value: 'samplerate'},
        {text: "Date Added", align: 'start', sortable: true, value: 'dateadded'},
        {text: "Size (MB)", align: 'start', sortable: true, value: 'sizemb'}
      ]
    },
    audioFileItems() {
      let ret = [];
      let items = _.cloneDeep(this.$store.state.uploadedAudioFiles);
      for(var i = 0; i < items.length; ++i) {
        const item = items[i];
        const str = this.shortDateStr(item.dateAdded);
        const obj = {
          name: item.name,
          duration: this.sec2Min(item.metadata.duration.toFixed(2)),
          samplerate: item.metadata.sampleRate,
          dateadded: str,
          sizemb: (item.size/1000000).toFixed(2),
          id: item.id,
          url: item.url
        }
        ret.push(obj);
      }
      return ret;
    },
    usernameAvailable() {
      if(this.requestedUsername !== this.requestedUsernameLastSubmitted) {
        return true;
      }
      else {
        if(this.usernameAvailableInDatabase) {
          return true;
        }
        return 'Username already in use '
      }
    }    
  },
  methods: {
    selectFilesClicked() {
      this.$refs.uploader.value = ''
      this.$refs.uploader.click()
    },
    onFileChange(event) {
      this.onlyPendingHaveErrors = false;
      let blob;
      let files = event.target.files;
      if(!files || files.length === 0) {
        return;
      }
      let fileData = []
      var self = this;
      var getFileData = function(file) {
          musicMetadata.parseBlob(file).then(metadata => {
            let obj = {
              formatInf: metadata.format,
              fileRef: file}
            fileData.push(obj);
            if(fileData.length === files.length) {
              self.checkDataOnAllFiles(fileData);
            }
          }).catch(e => {
            let obj = {
              formatInf: {codec: 'not-music'},
              fileRef: file}
            fileData.push(obj);
            if(fileData.length === files.length) {
              self.checkDataOnAllFiles(fileData);
            }
          });
      }
      files.forEach(file => {
        getFileData(file);
      });

    },
    checkDataOnAllFiles(fileData) {
      this.fileDataPendingUpload = [];
      const existingFiles = this.$store.state.uploadedAudioFiles
      console.log(existingFiles)
      // check they are all mp3
      // under the time limit
      // don't exceed the storage limit
      let sizeSum = this.sizeTotalOfUploadedFiles;
      let filenamesBeingUploaded = []
      const max = 3000  * 1000000;
      for(var i = 0; i < fileData.length; ++i) {
        let errs = [];
        const data = fileData[i];
        if(data.formatInf.codec !== "MPEG 1 Layer 3") {
          errs.push("Not a valid MP3 file");
        }
        if(data.formatInf.sampleRate !== 44100) {
          errs.push("Samplerate must be 44100");
        }
        if(data.formatInf.duration > 60 * 15) {
          errs.push("Duration greater than 15 min.")
        }
        if(this.filenameExists(data.fileRef.name, existingFiles)) {
          errs.push("File with this name already exists");
        }
        if(this.filenameExists(data.fileRef.name, filenamesBeingUploaded)) {
          errs.push("Can't upload files with duplicate names");
        }
        console.log("About to check track peak level");
        if(data.formatInf.trackPeakLevel == undefined) {
          console.log("[Dashboard] Track metadata.trackPeakLevel was undefined, so "
          + "setting it to 1");
          data.formatInf.trackPeakLevel = 1;
        }
        // if there are no errors, then add this size to the total and check for
        // going over the limit
        if(errs.length === 0) {
          const newSizeSum = sizeSum + data.fileRef.size;
          if(newSizeSum > max) {
            errs.push("Exceeds storage capacity");
          }
          else {
            sizeSum += data.fileRef.size;
          }
        }

        data.errs = errs;
        if(errs.length > 0) {
          data.uploadStatus = "Errors - will not upload"
        }
        else {
          data.uploadStatus = "Pending"
          filenamesBeingUploaded.push({name: data.fileRef.name});
        }
        this.fileDataPendingUpload.push(data);
        this.showPendingUpload = true;
      }
    },
    clearPendingFiles() {
      for(var i = 0; i < this.fileDataPendingUpload.length; ++i) {
        const item = this.fileDataPendingUpload[i];
        if(!item.uploadData) {
          continue
        }
        if(item.uploadData.inProgress) {
          console.log("Cancelling upload task for: " + item.fileRef.name);
          item.uploadData.uploadTask.cancel();
        }
      }

      this.fileDataPendingUpload = [];
      this.showPendingUpload = false;
    },
    uploadPendingFiles() {
      // Only try uploading the good ones
      let filesToUpload = [];
      for(var i = 0; i < this.fileDataPendingUpload.length; ++i) {
        if(this.fileDataPendingUpload[i].errs.length === 0) {
          filesToUpload.push(this.fileDataPendingUpload[i]);
        }
      }
      
      for(var i = 0; i < filesToUpload.length; ++i) {
        this.uploadFile(filesToUpload[i]);
      }
    },
    uploadFile(file) {
      var storageRef = fb.storage.ref();
      const storagePath = "user_files/" + this.$store.state.userProfile.uid 
      + "/audio/" + file.fileRef.name;

      // Create a reference to the file 
      var ref = storageRef.child(storagePath);
    
      var uploadTask = ref.put(file.fileRef);
      file.uploadData = {
        uploadTask: uploadTask,
        finished: false,
        cancelled: false,
        inProgress: true
      }
      uploadTask.on('state_changed', 
        (snapshot) => {
          var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          file.uploadStatus = "Uploading... (" + progress + "%)"
        },
        (err) => {
          console.log("Error with " + file.fileRef.name + " " + err)
          file.uploadStatus = "Error: " + err;
        },
        // now get the url of the object in google storage and assign to the db object
        () => {
          ref.getDownloadURL().then((url) => {
            file.uploadStatus = "Uploaded!"
            file.url = url;
            file.uploadData.finished = true;
            file.uploadData.inProgress = false;
            this.addFileInfoToDatabase(file);
          }).catch((err) => {
            file.uploadStatus = "Cancelled";
            file.uploadData.cancelled = true;
            file.uploadData.inProgress = false;
            console.log("Error getting uploaded file url: " + err)
          })
        }
      )
    },
    addFileInfoToDatabase(file) {
      const obj = {
        userId: this.$store.state.userProfile.uid,
        dateAdded: Date.now(),
        name: file.fileRef.name,
        size: file.fileRef.size,
        fileLastModified: file.fileRef.lastModified,
        metadata: file.formatInf,
        url: file.url
      }

      fb.audioFilesCollection.add(obj);
      this.checkPendingFilesAfterSuccesfulWrite(file);
    },
    checkPendingFilesAfterSuccesfulWrite(fileWritten) {
      this.fileDataPendingUpload = 
      this.fileDataPendingUpload.filter(el => el.fileRef.name !== fileWritten.fileRef.name);

      if(this.fileDataPendingUpload.length === 0) {
        this.showPendingUpload = false;
        return;
      }
      // if only files with errors remain, change button text
      let anyErrorsLeft = false;
      for(var i = 0; i < this.fileDataPendingUpload.length; ++i) {
        const data = this.fileDataPendingUpload[i];
        if(data.errs.length !== 0) {
          anyErrorsLeft = true;
        }
        break;
      }
      if(anyErrorsLeft) {
        this.onlyPendingHaveErrors = true;
      }
    },
    deleteSelectedFiles() {
      var storageRef = fb.storage.ref();
      const filesToDelete = _.cloneDeep(this.selectedAudioFiles);
      this.selectedAudioFiles = []
      for(var i = 0; i < filesToDelete.length; ++i) {
        const item = filesToDelete[i];
        const storagePath = "user_files/" + this.$store.state.userProfile.uid 
        + "/audio/" + item.name;
        // Create a reference to the file 
        var ref = storageRef.child(storagePath);
        ref.delete().then(() => {
          // remove it from the database
          fb.audioFilesCollection.doc(item.id).delete().then(() => {
          }).catch(err => {
            console.log("Error removing the audio file document: " + err)
          })
        }).catch(err => {
          console.log("Error deleting file: " + storagePath + ": " + err)
        });
      }
    },
    filenameExists(name, compArr) {
      for(var i = 0; i < compArr.length; ++i) {
        const str = compArr[i].name;
        if(str.localeCompare(name) === 0) {
          return true;
        }
      }
      return false;
    },
    shortDateStr(d) {
      const date = new Date(d);
      const options = {short: true}
      return date.toLocaleDateString(options) + "\n" + date.toLocaleTimeString(options)
    },
    sec2Min(sec) {
      let numMin = sec / 60;
      const numMinFloor = Math.floor(numMin);
      const remainingSec = sec - numMinFloor*60;
      let milliSec = remainingSec - Math.floor(remainingSec);
      milliSec = Math.floor(milliSec * 1000)
      let minString = "" + numMinFloor;
      minString = minString.padStart(2, '0');
      let secString = "" + Math.floor(remainingSec);
      secString = secString.padStart(2, '0');
      let msecString = milliSec + "";
      msecString = msecString.padStart(3, '0');
      const ret = minString + ":" + secString + ":" + msecString
      return ret;
    },
    logoutPressed() {
      this.$store.dispatch('logout');
    },
    chooseUsername() {
      this.usernameAvailableInDatabase = true;
      this.requestedUsernameLastSubmitted = this.requestedUsername
      console.log("wants " + this.requestedUsername)
      fb.usersCollection.where("displayName", "==", this.requestedUsername).get().then((docs) => {
        console.log(docs)
        if(docs.empty) {
          console.log("Can have!");
          fb.usersCollection.doc(this.$store.state.userProfile.uid).update({
            displayName: this.requestedUsernameLastSubmitted
          })
        }
        else {
          console.log("Taken :(")
          this.usernameAvailableInDatabase = false;
        }
      })

    }
  }


}
</script>

<style scoped>
.pendingUploadTable {
  white-space: pre-wrap;
  text-align: left;
}
.pendingUploadError {
  color: red;
}
.uploadStatusCell {
  width: 20%;
}
.existingAudioFilesDiv {
  margin-top: 30px;
}
</style>