import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Vuetify from 'vuetify';
import fb from './firebase'
import './assets/scss/app.scss'

Vue.use(Vuetify);
window._ = require('lodash')
Vue.config.productionTip = false

let app
fb.auth.onAuthStateChanged((userAuthInf) => {
  if (!app) {
    app = new Vue({
      vuetify : new Vuetify(),
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if(userAuthInf) {
    console.log("User logged in")
    console.log(userAuthInf);
    // create a simple user object
    const user = {
      email: userAuthInf.email,
      displayName: userAuthInf.displayName,
      uid: userAuthInf.uid
    }
    app.$store.dispatch('login', user)
  }
  else {
    console.log("I think user logged out");
  }
})
