<template>
  <h1>Profile</h1>
</template>

<script>

export default {
  name: 'Profile'

}
</script>
