import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init - add your own config here
var firebaseConfig = {
  apiKey: "AIzaSyC6_O_WmO2eqgZFhUSVhX7frjhC9J-QQcE",
  authDomain: "airtune-fb.firebaseapp.com",
  projectId: "airtune-fb",
  storageBucket: "airtune-fb.appspot.com",
  messagingSenderId: "133684487590",
  appId: "1:133684487590:web:8b5e3dd75763fd04725b67",
  measurementId: "G-NXBQMMNVXK"
};

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage();

// collection references
const usersCollection = db.collection('users')
const audioFilesCollection = db.collection('audiofiles')

// export utils/refs
export default {
  db,
  auth,
  storage,
  usersCollection,
  audioFilesCollection
}
